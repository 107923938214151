<template>
  <v-app>
    <Navigation />
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
    <v-snackbar v-model="notification.visible" :timeout="notification.timeout" vertical text :color="notificationColor">
      <div v-if="!!notification.timeout" style="position: absolute; top: 0; left: 0; right: 0;">
        <div :class="`${notificationColor} lighten-2`" style="position: absolute; width: 100%; height: 5px;"></div>
        <div :class="`${notificationColor} darken-2`" :style="{ width: `${progress}%`, height: '5px', position: 'absolute' }"></div>
      </div>

      <div class="text-subtitle-1 pb-2">{{ notification.title }}</div>

      <p v-if="notification.description">{{ notification.description }}</p>

      <template v-slot:action="{ attrs }">
        <v-btn text :color="notificationColor" v-bind="attrs" @click="notification.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script setup lang="ts">
import axios, { AxiosError } from "axios";
import { HTTPError } from "ky";
import { computed, ref } from "vue";
import Navigation from "./components/Navigation.vue";
import eventBus from "./services/event-bus";
import { AppNotification } from "./types/app-notification";

const notification = ref<Partial<AppNotification> & { visible: boolean }>({ visible: false });
const progress = ref(100);
const notificationColor = computed(() => {
  switch (notification.value.type) {
    case 'error': return 'red';
    case 'success': return 'green';
    case 'info':
    default:
      return 'blue';
  }
});

const interval = ref<ReturnType<typeof setTimeout>>();

// eslint-disable-next-line @typescript-eslint/no-misused-promises
eventBus.on('error', async (err) => {
  console.error(err);

  const val: AppNotification = {
    title: 'Une erreur est survenue',
    description: '',
    timeout: 10000,
    type: 'error',
  }

  if (err instanceof AxiosError && axios.isAxiosError(err)) {
    if (err.response && err.response.status !== 0) {
      if (err.response.status === 404) {
        val.title = `Élément non trouvé`;
        val.description = `L'élément demandé n'existe pas`;
      } else {
        val.title = `Erreur ${err.response?.status}: ${err.response?.statusText}`;
        val.description = (err.response?.data as any)?.data?.message ?? (err.response?.data as any)?.error?.description ?? `${err.response?.data}`;
      }
    } else {
      val.title = `${err.code}`;
      val.description = `${err.message}`;
    }
  } else if (err instanceof HTTPError) {
    const errorJson = await err.response.json();
    val.title = `${errorJson.statusCode} ${errorJson.error.type}`;
    val.description = `${errorJson.error.description}`;
  } else {
    val.description = `${err}`;
  }

  eventBus.notification(val);
});

eventBus.on('notification', (val) => {
  const appNotification = val as AppNotification;
  if (appNotification.timeout) {
    clearInterval(interval.value);
    interval.value = undefined;

    const timeout = appNotification.timeout;
    progress.value = 100;

    const ms = Math.max(timeout / 1000, 25);
    interval.value = setInterval(() => {
      progress.value -= 100 / (timeout / ms);
    }, ms);

    setTimeout(() => {
      clearInterval(interval.value);
      interval.value = undefined;
    }, timeout);
  }

  notification.value = {
    ...appNotification,
    visible: true,
  }
});

</script>
